import {createRouter, createWebHistory} from 'vue-router'
import Layout from "@/layout/Layout";

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import("@/views/Home"),
        meta: {
          title: "首页"
        }
      },
    ]
  },
  {
    path: '/user',
    name: 'User',
    component: () => import("@/views/User"),
    meta: {
      title: "用户中心"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login"),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/logging',
    name: 'Logging',
    component: () => import("@/views/Logging"),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/Register"),
    meta: {
      title: "注册"
    }
  },
  {
    path: '/map/:routeId/:ordersId?',
    name: 'Map',
    component: () => import("@/views/Map"),
    meta: {
      title: "路线图"
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import("@/views/Order"),
    meta: {
      title: "我的订单"
    }
  },
  {
    path: '/orderDetail/:ordersId',
    name: 'OrderDetail',
    component: () => import("@/views/OrderDetail"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: '/scanCode',
    name: 'ScanCode',
    component: () => import('@/views/ScanCode.vue'),
    meta: {
      title: "扫码"
    }
  },
  {
    path: '/choose/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Choose',
    component: () => import('@/views/Choose.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/number/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Number',
    component: () => import('@/views/Number.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/diff/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Diff',
    component: () => import('@/views/Diff.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/input/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Input',
    component: () => import('@/views/Input.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/photo/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Photo',
    component: () => import('@/views/Photo.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/scan/:routeId/:attendanceId/:ordersId/:issuesId/:issueIndex?',
    name: 'Scan',
    component: () => import('@/views/Scan.vue'),
    meta: {
      title: "问题"
    }
  },
  {
    path: '/rewards/:routeId?',
    name: 'Rewards',
    component: () => import('@/views/Rewards.vue'),
    meta: {
      title: "成绩奖励"
    },
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import('@/views/Ranking.vue'),
    meta: {
      title: "排行榜"
    }
  },
  {
    path: '/issue/:routeId?/:attendanceId?/:ordersId?/:issuesId?/:issuesTypeId?/:issueIndex?',
    name: 'Issue',
    component: () => import('@/views/Issue.vue'),
    meta: {
      title: "藏宝图"
    }
  },
  {
    path: '/gift',
    name: 'Gift',
    component: () => import('@/views/Gift.vue'),
    meta: {
      title: "兑换记录"
    }
  },
  {
    path: '/giftCode/:giftOrdersId',
    name: 'GiftCode',
    component: () => import('@/views/GiftCode.vue'),
    meta: {
      title: "礼品二维码"
    },
  },
  {
    path: '/record/:routeId/:ordersId',
    name: 'Record',
    component: () => import('@/views/Record.vue'),
    meta: {
      title: "打卡记录"
    }
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import('@/views/UserInfo.vue'),
    meta: {
      title: "个人信息"
    }
  },
  {
    path: '/userScore',
    name: 'UserScore',
    component: () => import('@/views/UserScore.vue'),
    meta: {
      title: "我的盘缠"
    }
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import('@/views/Review.vue'),
    meta: {
      title: "通关回顾"
    }
  },
  {
    path: '/reviewDetail/:routeId/:ordersId',
    name: 'ReviewDetail',
    component: () => import('@/views/ReviewDetail.vue'),
    meta: {
      title: "通关回顾"
    }
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/views/Address.vue'),
    meta: {
      title: "编辑地址"
    }
  },
  {
    path: '/pay/:routeId',
    name: 'Pay',
    component: () => import('@/views/Pay.vue'),
    meta: {
      title: "订单"
    }
  },
  {
    path: '/exchange/:routeId/:ordersId/:giftId/:giftType',
    name: 'Exchange',
    component: () => import('@/views/Exchange.vue'),
    meta: {
      title: "订单"
    }
  },
  {
    path: '/paygift/:routeId/:giftId/:giftType',
    name: 'PayGift',
    component: () => import('@/views/PayGift.vue'),
    meta: {
      title: "订单"
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
