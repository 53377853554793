import Header from "@/components/Header";
export default {
  name: "Layout",
  components: {
    Header
  },

  data() {
    return {
      user: {}
    };
  },

  created() {},

  methods: {}
};