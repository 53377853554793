export default {
  name: "Header",
  props: [],

  data() {
    return {};
  },

  created() {}

};