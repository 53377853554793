export default {
  name: "App",
  components: {},

  data() {
    return {};
  },

  setup() {},

  mounted() {
    this.$toast.clear();
  }

};