import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@vant/touch-emulator'; // 桌面端适配

// import VideoPlayer from 'vue-video-player';
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import 'leaflet/dist/leaflet.css'
import 'vant/lib/index.css';
import Vconsole from "vconsole"; //开发环境使用，生产环境自动取消
if (process.env.NODE_ENV == "development") { new Vconsole(); }
// new Vconsole();

// vant
import { Button, Image as VanImage,
    Col, Row, Tab, Tabs, Search,
    Tabbar, TabbarItem, List, Sticky, Tag, Cell,
    ConfigProvider, Divider, Icon, Picker, Field, Dialog as VanDialog,
    Progress, Toast, ImagePreview, Uploader, CellGroup, NavBar, NumberKeyboard,
    Step, Steps, CountDown, AddressEdit, Area, SubmitBar } from 'vant';

createApp(App).use(store).use(router)
    .use(Button).use(VanImage).use(Col).use(Row)
    .use(Tab).use(Tabs).use(Search).use(Tabbar).use(TabbarItem)
    .use(List).use(Sticky).use(Tag).use(Cell).use(ConfigProvider)
    .use(Divider).use(Icon).use(Picker).use(Field).use(VanDialog).use(Progress)
    .use(Toast).use(ImagePreview).use(Uploader).use(CellGroup).use(NavBar)
    .use(NumberKeyboard).use(Step).use(Steps).use(CountDown).use(AddressEdit)
    .use(Area).use(SubmitBar)
    // .use(VideoPlayer)
    .mount('#app');

