import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Header, {
    user: $data.user
  }, null, 8, ["user"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view, {
    style: {
      "flex": "1"
    },
    onUserInfo: _ctx.refreshUser
  }, null, 8, ["onUserInfo"])])]);
}